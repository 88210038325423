<template>
     <a class="anchor-np" id="contact"></a>

<section class="contact">
  <div class="container">
    <div class="row row-spacer">
      <div class="col-md-12">
        <h2 class="h-blue">Contact us</h2>
        <div class="simple-breaker small no-margin"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-md-offset-2">
        <p class="signoff">Get in touch today to find out how Dualtone can help you.</p>
        <div class="cta-wrap">
          <div class="cta-item">
            <div class="cta-icon">
              <span class="icon-envelope"></span>
            </div>
            <a style="text-decoration: none; background-color: none; color: black;" href="mailto:sales@dualtone.co.uk"><p>sales@dualtone.co.uk</p></a>
          </div>
          <div class="cta-item last">
            <div class="cta-icon">
              <span class="icon-telephone"></span>
            </div>
            <a style="text-decoration: none; background-color: none; color: black;" href="tel:+441202022220"><p>01202 022 220</p></a>
          </div>
        </div>
        <p class="company-name">Dualtone Communications Ltd</p>
        <p class="address">Unit 11, Albany Park, Cabot Lane, <br>Poole, Dorset, BH17 7BX</p>
      </div>
      <div class="col-md-4 col-md-offset-1">
        <form @submit.prevent="submitMail">
          <div class="form-group">
            <input v-model="form.name" class="full" type="text" name="name" placeholder="Name">
          </div>
          <div class="form-group">
            <input v-model="form.email" class="full" type="email" name="email" placeholder="Email address">
          </div>
          <div class="form-group ext-space">
            <textarea v-model="form.content" name="message" style="resize: vertical; max-height: 600px; min-height: 120px;" rows="7" placeholder="Your message"></textarea>
          </div>
          <div class="form-group">
            <input :disabled="!form.name || !form.email || !form.content || showSuccess || showError" class="wai-btn form-btn full-width" type="submit" value="Submit">
          </div>
          <slide-up-down v-model="showSuccess"><div class="sendAlert sendAlert-success"><button @click="showSuccess = false" type="button" class="buttonClose">×</button><strong>Your message has been sent. </strong></div></slide-up-down>
          <slide-up-down v-model="showError"><div class="sendAlert sendAlert-error"><button @click="showError = false" type="button" class="buttonClose">×</button><strong>Apologies, your message didn't send, please try again. </strong></div></slide-up-down>
        </form> 
      </div>
    </div>
  </div>
</section>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import SlideUpDown from 'vue3-slide-up-down'
export default {
  name: 'HomeContact',
  components: {
        SlideUpDown,
        axios,
    },
  data: () => {
    return {
      form: {
        name: '',
        email: '',
        content: '',
        subject: 'Website Contact',
      },
      mailResponse: false,
      showSuccess: false,
      showError: false,
      closeSuccess: false,
      closeError: false
    }
  },
  methods: {
    submitMail() {
        axios.post('https://mail.digidev.uk:1234/api/v1/form/2199656e-4f58-4cf2-b35d-a16a3eaa133c', this.form)
            .then((res) => {
                this.showSuccess = true
                setTimeout(() => {
                    this.showSuccess = false
                }, 4000)
            })
            .catch((error) => {
                this.showError = true
            }).finally(() => {
                this.form.name = ''
                this.form.email = ''
                this.form.content = ''
            });
    },
  }
} 

</script>
