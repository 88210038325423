<template>
    <section class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xs-6">
            <a href="index.html"><img src="images/dualtone-logo.svg" width="100px" height="auto"></a>
          </div>
          <div class="col-xs-6 visible-xs">
            <div class="footer-top">
              <a @click="scrollTop" class="wai-btn white y-w">Top <span class="icon-arrow-up"></span></a>
            </div>
          </div>
          <div class="col-md-6">
            <ul class="signoff-list">
            <li><a href="#wwd">What We Do</a></li>
            <!-- <li><a href="#casestudy">Case Study</a></li> -->
            <li><a href="#products">Products</a></li>
            <!-- <li><a href="#news">News</a></li> -->
            <li><a class="wai-btn-nav" href="#contact">Contact</a></li>
            </ul>
          </div>
        </div>
        <div class="row visible-xs">
          <ul class="social">
            <li><a href="#!"><i class="fa-brands fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="#!"><i class="fa-brands fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="#!"><i class="fa-brands fa-linkedin" aria-hidden="true"></i></a></li>
            <li><a href="#!"><i class="fa-brands fa-google" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </section>
    <section class="register">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>© Dualtone Communications Ltd {{new Date().getFullYear()}}. Company Reg: 08143991 VAT: GB140992114</p>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'MainFooter',
    methods: {
        scrollTop () {
      scrollTo(0,0)
    },
    }
}
</script>