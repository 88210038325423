<template>
        <a class="anchor" id="wwd"></a>
        <section class="simple">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <h2 class="h-blue">What we do</h2>
                <p>Using the best in open source technologies, we build outstanding products tailored to our customers' needs. Our team of highly experienced engineers build, operate and maintain everything in-house to ensure we retain full control of our infrastructure.</p>
                <div class="simple-breaker small no-margin"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="data" class="wwd">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="wwd-cont">
                  <div class="wwd-icon"><span class="icon-telephone"></span></div>
                  <h3>Hosted VoIP<br>Platform</h3>
                  <p>Our custom-built VoIP platform is the backbone of our telephony solutions. This is the enabler for our wide product range of Call Centre products, Conferencing and scalable Hosted Phone System offering.</p>
                  <div class="wwd-btn hidden-xs hidden-sm cursor-pointer">
                    <a @click="toggleData('1')" class="wai-btn" :class="{ 'active': data1 }">Find out more <span class="icon-arrow-right" :class="{ 'just-turn': data1 }"></span></a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wwd-cont">
                  <div class="wwd-icon"><span class="icon-code"></span></div>
                  <h3>Bespoke<br>Solutions</h3>
                  <p>Need something a little more made-to-measure? Get in touch to see how we can build to your exact needs using cutting edge technologies and our existing scalable, high-quailty VoIP infrastructure.</p>
                  <div class="wwd-btn hidden-xs hidden-sm">
                    <a @click="toggleData('2')" class="wai-btn" :class="{ 'active': data2 }">Find out more <span class="icon-arrow-right" :class="{ 'just-turn': data2 }"></span></a>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wwd-cont last">
                  <div class="wwd-icon"><span class="icon-lan"></span></div>
                  <h3>Internet<br>Connectivity</h3>
                  <p>We are suppliers of everything from xDSL through to full-fibre solutions and inter-site connectivity. Business struggling with poor broadband coverage? Enquire about our high-speed wireless coverage!</p>
                  <div class="wwd-btn hidden-xs hidden-sm">
                    <a @click="toggleData('3')" class="wai-btn" :class="{ 'active': data3 }">Find out more <span class="icon-arrow-right" :class="{ 'just-turn': data3 }"></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <slide-up-down v-model="data1">
        <div id="data1" class="container hidden-xs hidden-sm">
          <div class="wwd-cont-expand">
            <div class="row">
              <div class="col-md-12">
                <div class="header">
                  <div class="wwd-icon">
                    <span class="icon-telephone"></span>
                  </div>
                  <h2>Telecoms Consultancy</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Impartial advice</p>
                    <p class="body-plus">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Technical consultancy</p>
                    <p class="margin-zero">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <p>and implemented correctly. We can advise on everything from technology choice through to network connectivity and everything in between. We will highlight potential bottlenecks, areas of security risk and single points of failure and offer advice on how to overcome these issues.</p>
                <p>All aspects of business telecommunications – we cover all aspects of business telecoms including:</p>
              </div>
              <div class="col-md-4">
                <ul>
                  <li><span class="icon-plus"></span> Fixed line telecoms</li>
                  <li><span class="icon-plus"></span> Mobile telecoms</li>
                  <li><span class="icon-plus"></span> Connectivity</li>
                  <li><span class="icon-plus"></span> Multi-site systems</li>
                  <li><span class="icon-plus"></span> CRM integration</li>
                </ul>
                <div class="ctas">
                  <div class="enquire">
                    <a href="#contact" class="wai-btn yellow-block"><span class="icon-envelope"></span> Enquire Now</a>
                  </div>
                  <div class="close-box">
                    <a @click="data1 = false" class="wai-btn small"><span class="icon-cross"></span> Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-up-down>

      <slide-up-down v-model="data2">
        <div id="data2" class="container hidden-xs hidden-sm">
          <div class="wwd-cont-expand">
            <div class="row">
              <div class="col-md-12">
                <div class="header">
                  <div class="wwd-icon">
                    <span class="icon-telephone"></span>
                  </div>
                  <h2>So Bespoke</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Impartial advice</p>
                    <p class="body-plus">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Technical consultancy</p>
                    <p class="margin-zero">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <p>and implemented correctly. We can advise on everything from technology choice through to network connectivity and everything in between. We will highlight potential bottlenecks, areas of security risk and single points of failure and offer advice on how to overcome these issues.</p>
                <p>All aspects of business telecommunications – we cover all aspects of business telecoms including:</p>
              </div>
              <div class="col-md-4">
                <ul>
                  <li><span class="icon-plus"></span> Fixed line telecoms</li>
                  <li><span class="icon-plus"></span> Mobile telecoms</li>
                  <li><span class="icon-plus"></span> Connectivity</li>
                  <li><span class="icon-plus"></span> Multi-site systems</li>
                  <li><span class="icon-plus"></span> CRM integration</li>
                </ul>
                <div class="ctas">
                  <div class="enquire">
                    <a href="#contact" class="wai-btn yellow-block"><span class="icon-envelope"></span> Enquire Now</a>
                  </div>
                  <div class="close-box">
                    <a @click="data2 = false" class="wai-btn small"><span class="icon-cross"></span> Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-up-down>

      <slide-up-down v-model="data3">
        <div id="data3" class="container hidden-xs hidden-sm">
          <div class="wwd-cont-expand">
            <div class="row">
              <div class="col-md-12">
                <div class="header">
                  <div class="wwd-icon">
                    <span class="icon-telephone"></span>
                  </div>
                  <h2>We are literally the internet</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Impartial advice</p>
                    <p class="body-plus">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="title">Technical consultancy</p>
                    <p class="margin-zero">We can offer a full and impartial review of your current telecoms and connectivity set up, highlighting areas of concern and potential options to ensure future-proofing your business communications.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <p>and implemented correctly. We can advise on everything from technology choice through to network connectivity and everything in between. We will highlight potential bottlenecks, areas of security risk and single points of failure and offer advice on how to overcome these issues.</p>
                <p>All aspects of business telecommunications – we cover all aspects of business telecoms including:</p>
              </div>
              <div class="col-md-4">
                <ul>
                  <li><span class="icon-plus"></span> Fixed line telecoms</li>
                  <li><span class="icon-plus"></span> Mobile telecoms</li>
                  <li><span class="icon-plus"></span> Connectivity</li>
                  <li><span class="icon-plus"></span> Multi-site systems</li>
                  <li><span class="icon-plus"></span> CRM integration</li>
                </ul>
                <div class="ctas">
                  <div class="enquire">
                    <a href="#contact" class="wai-btn yellow-block"><span class="icon-envelope"></span> Enquire Now</a>
                  </div>
                  <div class="close-box">
                    <a @click="data3 = false" class="wai-btn small"><span class="icon-cross"></span> Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-up-down>
</template>

<script>
import SlideUpDown from 'vue3-slide-up-down'
export default {
    name: 'HomeWhatWeDo',
    components: {
        SlideUpDown
    },
    data() {
        return {
        data1: false,
        data2: false,
        data3: false
        }
    },
    methods: {
        toggleData(num) {
            ['1', '2', '3'].forEach(n => {
                if (num === n) {
                    this['data' + n] = !this['data' + n]
                }
                else {
                    this['data' + n] = false
                }
            })

            const scrollPos = document.getElementById('data').offsetTop
            scrollTo(scrollX, scrollPos - 170)
        },
    }
}

</script>