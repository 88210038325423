<template>
    <section class="logo-block">
            <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3">
                <p>Providing high quality, flexible VoIP solutions to businesses of <span>all shapes and sizes</span></p>
                </div>
            </div>
            <div class="row row-flex">
                <div class="col-md-3 col-md-12">
                <div class="logo"><img src="images/dot-logo.png" /></div>
                </div>
                <div class="col-md-3 col-md-12">
                <div class="logo"><img src="images/made-logo.png" /></div>
                </div>
                <div class="col-md-3 col-md-12">
                <div class="logo"><img src="images/amplify-logo.png" /></div>
                </div>
                <div class="col-md-3 col-md-12">
                <div class="logo logo-last"><img src="images/hilti-logo.png" /></div>
                </div>
            </div>
            </div>
        </section>
</template>

<script>
</script>