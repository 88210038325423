<template>
    <a class="anchor" id="products"></a>
    <section class="simple">
        <div class="container">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
            <h2 class="h-blue">Our Products</h2>
            <div class="simple-breaker small no-margin"></div>
            </div>
        </div>
        </div>
    </section>

    <section class="products">
        <div class="container">
        <div class="row">
            <div class="col-md-5 col-md-offset-1">
            <div class="product-wrap">
                <div class="logo"><img src="images/innovovoice-logo.svg" width="280px"></div>
                <p class="title">Cloud-based VoIP Phone System</p>
                <p class="copy">In at lobortis magna. Sed sit amet nisl cursus,  blandit neque a, finibus quam. Proin volutpat convallis dui quis accumsan. Praesent commodo feugiat velit, facilisis varius risus euismod sed. Sed varius neque eget eros pharetra vulputate. Pellentesque tristique enim ac ante commodo, finibus elementum erat bibendum.</p>
                <p><a href="http://innovovoice.com/" target="_blank" class="wai-btn center hidden-xs">Find out more</a></p>
            </div>
            </div>
            <div class="col-md-5">
            <div class="product-wrap last">
                <div class="logo"><img src="images/innovodial-logo.svg" width="280px"></div>
                <p class="title">Cloud-based Campaign Dialling</p>
                <p class="copy">InnovoDial from Dualtone is a scalable cloud-based preview and progressive campaign dialler with powerful data capture and analytics. Get in tune with your campaigns today and realise the true performance of your data. Save time, increase performance, increase visibility and all this for one low monthly cost!</p>
                <p><a href="http://innovodial.com/" target="_blank" class="wai-btn center hidden-xs">Find out more</a></p>
            </div>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
</script>