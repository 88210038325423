<template>
    <nav class="wai-nav">
            <div class="wai-nav-logo">
                <a href="index.html"><img src="images/dualtone-logo.svg" width="100px" height="auto"></a>
            </div>
            <ul class="wai-nav-list">
                <li><a href="#wwd">What We Do</a></li>
                <!-- <li><a href="#casestudy">Case Study</a></li> -->
                <li><a href="#products">Products</a></li>
                <!-- <li><a href="#news">News</a></li> -->
                <li><a class="wai-btn-nav" href="#contact">Contact</a></li>
            </ul>
            <div class="wai-nav-icon">
                <a href="#!"><img src="images/wai-nav-icon.svg" width="30px" height="30px" /></a>
            </div>
        </nav>
</template>

<script>
</script>