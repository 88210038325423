<template>
  <MainNavbar />
  <router-view />
  <MainFooter />
</template>

<script setup>
import MainNavbar from './components/Global/MainNavbar.vue'
import MainFooter from './components/Global/MainFooter.vue'
</script>

<script>

export default {
  name: 'App',
} 

</script>

