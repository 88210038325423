<template>
  <HomeHero />
  <HomeWhatWeDo />
  <!-- <HomeCaseStudy /> -->
  <HomeCompanies />
  <HomeProducts />
  <HomeTechnical />
  <!-- <HomeNews /> -->
  <!-- <HomeBlog /> -->
  <HomeContact />
  <HomePreFooter />
</template>

<script setup>
/* eslint-disable */
import HomeHero from '../components/Home/HomeHero.vue'
import HomeWhatWeDo from '../components/Home/HomeWhatWeDo.vue'
import HomeCaseStudy from '../components/Home/HomeCaseStudy.vue'
import HomeCompanies from '../components/Home/HomeCompanies.vue'
import HomeProducts from '../components/Home/HomeProducts.vue'
import HomeTechnical from '../components/Home/HomeTechnical.vue'
import HomeNews from '../components/Home/HomeNews.vue'
import HomeBlog from '../components/Home/HomeBlog.vue'
import HomeContact from '../components/Home/HomeContact.vue'
import HomePreFooter from '../components/Home/HomePreFooter.vue'
</script>

<script>
  export default {
  name: 'HomePage',
  methods: {
    scrollTop () {
      scrollTo(0,0)
    },
  }
} 

</script>