<template>
    <section class="logo-block signoff">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <p>“Dualtone really took the time to understand our business. <span>Their service was exceptional.</span>”</p>
            <p class="name">James Ruskin, Operations Director <span>MADE</span></p>
          </div>
        </div>
        <div class="row row-flex row-spacer">
          <div class="col-md-3 hidden-xs">
            <div class="logo"><img src="images/dot-logo-light.png" /></div>
          </div>
          <div class="col-md-3">
            <div class="logo"><img src="images/made-logo-light.png" /></div>
          </div>
          <div class="col-md-3 hidden-xs">
            <div class="logo"><img src="images/amplify-logo-light.png" /></div>
          </div>
          <div class="col-md-3 hidden-xs">
            <div class="logo"><img src="images/hilti-logo-light.png" /></div>
          </div>
        </div>
        <div class="cta hidden-xs">
          <p><a href="#!" class="wai-btn yellow-block">Contact</a></p>
        </div>
      </div>
    </section>
</template>

<script>
</script>
