<template>
    <section class="technical hidden-xs">
        <div class="container">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
            <h2 class="h-blue">We're technical</h2>
            <div class="simple-breaker small no-margin"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
            <p><img src="images/tech-graphic.svg" width="840px"></p>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
</script>