<template>
    <section class="hero">
        <div class="container" style="padding-bottom: 100px">
            <div class="row">
                <div class="col-md-8 col-md-offset-2">
                    <h1>Providing high quality, flexible VoIP solutions to businesses of <span>all shapes and sizes</span></h1>
                </div>
            </div>
            <div class="row hidden-xs">
            <div class="col-md-12">
                <div class="breaker-line"></div>
            </div>
            </div>
            <div class="hero-ctas">
            <div class="row">
                <div class="col-md-2 col-md-offset-2">
                <a href="#!">
                    <div class="cta">
                        <div class="icon">
                        <span class="icon-pencil-ruler2"></span>
                        </div>
                        <div class="text">
                        <p>Bespoke solutions for your business</p>
                        </div>
                    </div>
                </a>
                </div>
                <div class="col-md-2 hidden-xs">
                <a href="#!">
                    <div class="cta">
                        <div class="icon">
                        <span class="icon-cloud-upload"></span>
                        </div>
                        <div class="text">
                        <p>Off-site hosting for extra security</p>
                        </div>
                    </div>
                </a>
                </div>
                <div class="col-md-2 hidden-xs">
                <a href="#!">
                    <div class="cta">
                        <div class="icon">
                        <span class="icon-telephone"></span>
                        </div>
                        <div class="text">
                        <p>Call recording and compliance software</p>
                        </div>
                    </div>
                </a>
                </div>
                <div class="col-md-2 hidden-xs">
                <a href="#!">
                    <div class="cta">
                        <div class="icon">
                        <span class="icon-timer2"></span>
                        </div>
                        <div class="text">
                        <p>24 hour telephone and email support</p>
                        </div>
                    </div>
                </a>
                </div>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
</script>